



















import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

import firebase from 'firebase/app';
import { format } from 'date-fns';
import { dayCountLoaderSingleton } from './DayCountLoader';
import { db, dayCountsCollection } from './db';
import { DayCount } from './dbTypes';
import * as Day from './Day';

@Component({
  props: {
    dayId: {
      type: String,
      required: true,
    },
  },
})
export default class CalendarDay extends Vue {
  @Prop() private dayId!: string;

  get formattedDate(): string {
    return this.day ? format(Day.toNative(this.day), `EEEE, MMM d`) : ``;
  }

  get day(): Day.Day | null {
    return dayCountLoaderSingleton.getDayFromId(this.dayId);
  }

  get dayCount(): DayCount | null {
    return dayCountLoaderSingleton.getLoadedDay(this.dayId);
  }

  get countClass(): string | null {
    if (!this.dayCount) {
      return null;
    }

    if (this.dayCount.count === 0) {
      return `zero`;
    }
    if (this.dayCount.count === 1) {
      return `one`;
    }
    if (this.dayCount.count === 2) {
      return `two`;
    }
    if (this.dayCount.count >= 3) {
      return `more`;
    }

    return null;
  }

  // Add or subtract the given amount from the count
  private increment(amount: number): void {
    if (!this.dayCount || !this.day) {
      return;
    }

    const incrementer = firebase.firestore.FieldValue.increment(amount);

    const batch = db.batch();
    if (this.dayCount.count === 0) {
      // If the count is 0, the document might not yet exist in the database, so create it
      const { dayOfYear, year } = this.day;
      batch.set(dayCountsCollection.doc(this.dayId), { day: { dayOfYear, year }, count: incrementer });
    } else {
      // Otherwise update it
      batch.update(dayCountsCollection.doc(this.dayId), { count: incrementer });
    }
    batch.update(dayCountsCollection.doc(`meta`), { totalCount: incrementer });
    batch.commit().catch((err) => {
      throw err;
    });
  }
}
