import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';

import Vue from 'vue';
import App from './App.vue';

// On mobile, calculate the height of the viewport not including the search bar
function updateVhProp() {
  document.documentElement.style.setProperty(`--vh`, `${window.innerHeight * 0.01}px`);
}

window.addEventListener(`resize`, () => updateVhProp());
updateVhProp();

if (`serviceWorker` in navigator) {
  window.addEventListener(`load`, () => {
    // Assign to a temporary variable to prevent Parcel from aggressively processing the generated
    // service worker file
    const swName = `/sw.js`;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.serviceWorker.register(swName);
  });
}

document.addEventListener(`DOMContentLoaded`, () => {
  // eslint-disable-next-line no-new
  new Vue({
    el: `#app`,
    render: (h) => h(App),
  });
}, false);
