import Vue from 'vue';
import intersectionBy from 'lodash/intersectionBy';
import partition from 'lodash/partition';
import xorBy from 'lodash/xorBy';
import { addDays, differenceInDays, startOfDay } from 'date-fns';
import firebase from 'firebase/app';
import { dayCountsCollection } from './db';
import * as Day from './Day';
import { DayCount, DayCountMeta } from './dbTypes';

type DayWithId = Day.Day & { id: string };

type DayCountChunk = {
  loading: boolean,
  days: DayWithId[],
  dayCounts: DayCount[],
  cleanup: () => void,
};

type VueObservable<T> = { value: T };

export class DayCountLoader {
  private firstLoadedDayIndex: number | null = null;

  private lastLoadedDayIndex: number | null = null;

  private daysObservable: VueObservable<DayWithId[]> = Vue.observable({ value: [] as DayWithId[] });

  private chunksObservable: VueObservable<DayCountChunk[]> = Vue.observable({ value: [] as DayCountChunk[] });

  constructor() {
    dayCountsCollection.doc(`meta`).onSnapshot((snap: firebase.firestore.DocumentSnapshot) => {
      if (!snap.exists) {
        return;
      }

      this.setDays(DayCountLoader.generateAllDays((snap.data() as DayCountMeta).firstDay));
    });
  }

  // Return an array of all possible days given the provided starting point
  static generateAllDays(firstDay: Day.Day): DayWithId[] {
    const firstDate: Date = Day.toNative(firstDay);
    const numDays: number = differenceInDays(startOfDay(new Date()), firstDate);
    return [...(new Array(numDays + 1) as unknown[])].map((_, index: number): DayWithId => {
      const day: Day.Day = Day.fromNative(addDays(firstDate, index));
      return { id: Day.getUniqueKey(day), ...day };
    }).reverse();
  }

  // Observable getters and setters
  private get days(): DayWithId[] {
    return this.daysObservable.value;
  }

  private set days(newDays: DayWithId[]) {
    this.daysObservable.value = newDays;
  }

  private get chunks(): DayCountChunk[] {
    return this.chunksObservable.value;
  }

  private set chunks(newChunks: DayCountChunk[]) {
    this.chunksObservable.value = newChunks;
  }

  // Return the list of days
  getDays(): DayWithId[] {
    return this.days;
  }

  // Populate the list of possible days
  private setDays(newDays: DayWithId[]): void {
    const oldDays: DayWithId[] = this.days;

    // Check whether the old days and new days are equal
    if (xorBy(oldDays, newDays, `id`).length === 0) {
      // The days haven't changed so there's nothing left to do
      return;
    }

    if (intersectionBy(this.getLoadedDays(), newDays, `id`).length === 0) {
      // There is no overlap between the old loaded days and the new set of days, which means that we have none of
      // the new days loaded, so reset the loaded day indices
      this.firstLoadedDayIndex = null;
      this.lastLoadedDayIndex = null;
    } else if (this.firstLoadedDayIndex !== null && this.lastLoadedDayIndex !== null) {
      const previousFirstLoadedDay: DayWithId = oldDays[this.firstLoadedDayIndex];
      const previousLastLoadedDay: DayWithId = oldDays[this.lastLoadedDayIndex];

      // Try to find the start of the loaded days in the new days
      this.firstLoadedDayIndex = newDays.findIndex((day: DayWithId) => day.id === previousFirstLoadedDay.id);
      this.lastLoadedDayIndex = newDays.findIndex((day: DayWithId) => day.id === previousLastLoadedDay.id);

      if (this.firstLoadedDayIndex === -1) {
        // If it's not there, start at the new beginning
        this.firstLoadedDayIndex = 0;
      }

      // Try to find the end of the loaded days in the new days
      this.lastLoadedDayIndex = newDays.findIndex((day: DayWithId) => day.id === previousLastLoadedDay.id);
      if (this.lastLoadedDayIndex === -1) {
        // If it's not there, end at the new end
        this.lastLoadedDayIndex = newDays.length - 1;
      }
    }

    this.days = newDays;

    this.pruneChunks(this.firstLoadedDayIndex, this.lastLoadedDayIndex);
  }

  // Return an array of all the days that have been loaded
  getLoadedDays(): DayCount[] {
    return this.chunks.flatMap((chunk: DayCountChunk) => (chunk.loading ? [] : chunk.dayCounts));
  }

  // Lookup a loaded day by its id, returning null if it hasn't been loaded
  getLoadedDay(id: string): DayCount | null {
    return this.getLoadedDays().find((dayCount: DayCount) => Day.getUniqueKey(dayCount.day) === id) || null;
  }

  // Lookup a day from its id
  getDayFromId(id: string): DayWithId | null {
    return this.getDays().find((day: DayWithId) => Day.getUniqueKey(day) === id) || null;
  }

  // Ensure that a frame of days between the start and end indices, inclusive are loaded
  loadFrame(frameStartIndex: number, frameEndIndex: number): void {
    const frameLength: number = frameEndIndex - frameStartIndex + 1;

    if (this.getDays().length === 0 || frameLength === 0) {
      return;
    }

    if (this.firstLoadedDayIndex === null || this.lastLoadedDayIndex === null) {
      // No days are loaded yet, so load all the days between start and end as the first chunk
      this.loadNewChunk(frameStartIndex, frameEndIndex);
      this.firstLoadedDayIndex = frameStartIndex;
      this.lastLoadedDayIndex = frameEndIndex;
      return;
    }

    // If the start of the frame is outside of the loaded days
    if (frameStartIndex < this.firstLoadedDayIndex) {
      // Load new days before the already loaded days

      // Load from the first day in the frame or load a frame full of days, whichever starts first
      const chunkStartIndex: number = Math.min(frameStartIndex, this.firstLoadedDayIndex - frameLength);
      // Load until the day right before the beginning of the already loaded days
      const chunkEndIndex: number = this.firstLoadedDayIndex - 1;

      this.loadNewChunk(chunkStartIndex, chunkEndIndex);
      this.firstLoadedDayIndex = Math.max(chunkStartIndex, 0);
    }

    // If the end of the frame is outside of the loaded days
    if (frameEndIndex > this.lastLoadedDayIndex) {
      // Load new days after the already loaded days

      // Load from the day right after the end of the already loaded days
      const chunkStartIndex: number = this.lastLoadedDayIndex + 1;
      // Load from the last day in the frame or load a frame full of days, whichever ends last
      const chunkEndIndex: number = Math.max(frameEndIndex, this.lastLoadedDayIndex + frameLength);

      this.loadNewChunk(chunkStartIndex, chunkEndIndex);
      this.lastLoadedDayIndex = Math.min(chunkEndIndex, this.days.length - 1);
    }

    this.pruneChunks(frameStartIndex, frameEndIndex);
  }

  // Load a new chunk of days from the start to end indices, inclusive
  private loadNewChunk(startIndex: number, endIndex: number): void {
    // Do bounds checking on the start and end indices
    const newDays: DayWithId[] = this.days.slice(Math.max(startIndex, 0), Math.min(endIndex + 1, this.days.length));
    if (newDays.length === 0) {
      return;
    }

    // Load the chunk's days from the database
    const firstDay: DayWithId = newDays[0];
    const lastDay: DayWithId = newDays[newDays.length - 1];
    const newChunk: DayCountChunk = {
      loading: true,
      days: newDays,
      dayCounts: [],
      cleanup: dayCountsCollection
        .orderBy(`day.year`, `desc`)
        .orderBy(`day.dayOfYear`, `desc`)
        .startAt(firstDay.year, firstDay.dayOfYear)
        .endAt(lastDay.year, lastDay.dayOfYear)
        .onSnapshot((snap: firebase.firestore.QuerySnapshot) => {
          // Index the newly-loaded day counts by their id to make looking them up easier
          const dayCountsById = new Map<string, DayCount>(snap.docs.map((doc) => [doc.id, doc.data() as DayCount]));

          newChunk.loading = false;
          newChunk.dayCounts = newDays.map(({ id, dayOfYear, year }): DayCount => ({
            count: dayCountsById.get(id)?.count || 0, // use 0 if the day count isn't present in the database
            day: { dayOfYear, year },
          }));
        }),
    };
    this.chunks.push(newChunk);
  }

  // Discard all chunks that are no longer included in the given frame
  private pruneChunks(frameStartIndex: number | null, frameEndIndex: number | null) {
    const keptDays: DayWithId[] = frameStartIndex === null || frameEndIndex === null
      ? [] // if the frame is empty, keep no days
      : this.getDays().slice(frameStartIndex, frameEndIndex + 1);

    // Keep the chunks that contain days that are in the frame and remove the chunks that only contain days that are
    // not in the frame
    const [keptChunks, removedChunks]: [DayCountChunk[], DayCountChunk[]] = partition(
      this.chunks,
      (chunk: DayCountChunk) => {
        const chunkDays: DayWithId[] = chunk.days;

        // Keep this chunk if it contains any kept days
        return intersectionBy(chunkDays, keptDays, `id`).length > 0;
      },
    );

    this.chunks = keptChunks;

    // Dispose of the removed chunks
    removedChunks.forEach((chunk: DayCountChunk) => {
      chunk.cleanup();
    });

    // Recalculate the first and last loaded day indices if any chunks were removed
    if (removedChunks.length > 0) {
      [this.firstLoadedDayIndex, this.lastLoadedDayIndex] = this.chunks
        .flatMap((chunk: DayCountChunk) => chunk.days)
        .reduce(([firstIndex, lastIndex], { id: dayId }): [number, number] => {
          const dayIndex: number = this.days.findIndex((day: DayWithId) => day.id === dayId);
          return [Math.min(firstIndex, dayIndex), Math.max(lastIndex, dayIndex)];
        }, [Infinity, -Infinity]);

      if (this.firstLoadedDayIndex === Infinity && this.lastLoadedDayIndex === -Infinity) {
        // No days were loaded, so reset the loaded day indices
        this.firstLoadedDayIndex = null;
        this.lastLoadedDayIndex = null;
      }
    }
  }
}

export const dayCountLoaderSingleton = new DayCountLoader();
