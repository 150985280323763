import {
  getDayOfYear, getYear, setDayOfYear, setYear, startOfDay,
} from 'date-fns';

export type Day = {
  dayOfYear: number;
  year: number;
}

// Convert a day object into a native Date object
export function toNative(day: Day): Date {
  return setDayOfYear(setYear(startOfDay(new Date()), day.year), day.dayOfYear);
}

// Convert a native Date object into a day object
export function fromNative(date: Date): Day {
  const dayOfYear: number = getDayOfYear(date);
  const year: number = getYear(date);
  return { dayOfYear, year };
}

// Return a key that is guaranteed to be unique for different days and identical for identical days
export function getUniqueKey(day: Day): string {
  return `${day.year}:${day.dayOfYear}`;
}
