






import { Component, Vue } from 'vue-property-decorator';

import { dayCountsCollection } from './db';
import { DayCountMeta } from './dbTypes';

@Component({
  firestore: {
    dayCountMeta: dayCountsCollection.doc(`meta`),
  },
})
export default class TotalCount extends Vue {
  private dayCountMeta: DayCountMeta | null = null;
}
