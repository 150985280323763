










import { Component, Vue } from 'vue-property-decorator';

import AttributionMessage from './AttributionMessage.vue';
import CountCalendar from './CountCalendar.vue';
import TotalCount from './TotalCount.vue';

@Component({
  components: {
    AttributionMessage,
    CountCalendar,
    TotalCount,
  },
})
export default class App extends Vue {}
