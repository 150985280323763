


























import { Component, Vue } from 'vue-property-decorator';

import { RecycleScroller } from 'vue-virtual-scroller';
import { dayCountLoaderSingleton } from './DayCountLoader';
import CalendarDay from './CalendarDay.vue';
import * as Day from './Day';

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

type DayWithId = Day.Day & { id: string };

@Component({
  components: {
    CalendarDay,
    RecycleScroller,
  },
})
export default class CountCalendar extends Vue {
  $refs!: {
    child: Element
  }

  private itemHeight: number | null = null;

  get days(): DayWithId[] {
    return dayCountLoaderSingleton.getDays();
  }

  private mounted(): void {
    this.measureItemHeight();
    // eslint-disable-next-line @typescript-eslint/unbound-method
    window.addEventListener(`resize`, this.measureItemHeight);
  }

  private beforeDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    window.removeEventListener(`resize`, this.measureItemHeight);
  }

  private measureItemHeight(): void {
    // Calculate the height of the fake calendar day item so that we know how high each of the real day items will be
    this.itemHeight = Math.floor(this.$refs.child.getBoundingClientRect().height);
  }

  // This event is called by RecycleScroller to let us know which items are in view
  private onUpdate(start: number, end: number): void {
    dayCountLoaderSingleton.loadFrame(start, end);
  }
}
