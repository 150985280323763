import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { firestorePlugin } from 'vuefire';

Vue.use(firestorePlugin);

firebase.initializeApp({
  apiKey: `AIzaSyB-DR8KE4e7mKFiRReJ5BWmLNnI8plExvg`,
  authDomain: `whacker-tracker.firebaseapp.com`,
  databaseURL: `https://whacker-tracker.firebaseio.com`,
  projectId: `whacker-tracker`,
  storageBucket: `whacker-tracker.appspot.com`,
  messagingSenderId: `60749855966`,
  appId: `1:60749855966:web:2937a096873e113ae9964f`,
});

// eslint-disable-next-line @typescript-eslint/no-floating-promises
firebase.firestore().enablePersistence({
  synchronizeTabs: true,
}).catch((err: firebase.firestore.FirestoreError) => {
  // Ignore errors with persistence storage not being enabled, which is the case during testing
  if (err.code !== `unimplemented`) {
    throw err;
  }
});

const isDev = window.location.host.startsWith(`localhost`);

export const db = firebase.firestore();
export const dayCountsCollection = db.collection(isDev ? `dayCountsDev` : `dayCounts`);
